import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import TrialSection from '../components/pageSections/Trial/index'
import InquiryFormSection from '../components/pageSections/InquryForm/index'

const TrialApplicationPage = ({ location: { pathname } }) => (
  <Layout>
    <SEO
      title="体験レッスンのご案内　| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
      description="モバプロの体験レッスンでは、スピーキングチェックを含めた40分間のレッスンを体験して頂くことができます。お気軽にお試しください。"
      pathName={pathname}
    />
    <TrialSection isTrialPage />
    <InquiryFormSection type="trial" />
  </Layout>
)

TrialApplicationPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

TrialApplicationPage.defaultProps = {
  location: null,
}

export default TrialApplicationPage
